exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-buy-electric-index-jsx": () => import("./../../../src/pages/buy_electric/index.jsx" /* webpackChunkName: "component---src-pages-buy-electric-index-jsx" */),
  "component---src-pages-buy-index-jsx": () => import("./../../../src/pages/buy/index.jsx" /* webpackChunkName: "component---src-pages-buy-index-jsx" */),
  "component---src-pages-buy-sports-index-jsx": () => import("./../../../src/pages/buy_sports/index.jsx" /* webpackChunkName: "component---src-pages-buy-sports-index-jsx" */),
  "component---src-pages-city-index-jsx": () => import("./../../../src/pages/city/index.jsx" /* webpackChunkName: "component---src-pages-city-index-jsx" */),
  "component---src-pages-disposal-index-jsx": () => import("./../../../src/pages/disposal/index.jsx" /* webpackChunkName: "component---src-pages-disposal-index-jsx" */),
  "component---src-pages-event-index-jsx": () => import("./../../../src/pages/event/index.jsx" /* webpackChunkName: "component---src-pages-event-index-jsx" */),
  "component---src-pages-life-index-jsx": () => import("./../../../src/pages/life/index.jsx" /* webpackChunkName: "component---src-pages-life-index-jsx" */),
  "component---src-pages-qa-index-jsx": () => import("./../../../src/pages/qa/index.jsx" /* webpackChunkName: "component---src-pages-qa-index-jsx" */),
  "component---src-pages-recycle-index-jsx": () => import("./../../../src/pages/recycle/index.jsx" /* webpackChunkName: "component---src-pages-recycle-index-jsx" */),
  "component---src-pages-work-index-jsx": () => import("./../../../src/pages/work/index.jsx" /* webpackChunkName: "component---src-pages-work-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */)
}

